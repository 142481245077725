import { Injectable } from '@angular/core';
import { urlConstant } from './constant/urlConstant';
import { CommonService} from './common/commonService';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})

export class appService {
    constructor(public _commonService: CommonService) { }

    createLogs(data) {
        return this._commonService.post(urlConstant.accountModule.createLogs,data)
          .pipe(map((x: Response) => x));
      }
    
      getLogs() {
        return this._commonService.get(urlConstant.accountModule.getAllLogs)
        .pipe(map((x:Response) => x));
      }

}
