import { Component, OnInit } from '@angular/core';
import { messageConstant } from '../../constant/messageConstant';
import { dataConstant } from '../../constant/dataConstant';
import { CommonService } from '../../common/commonService';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { appService } from '../../app.service';
import { async } from '@angular/core/testing';
@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {

  loginuserdetail: any;
  authenticationData: any = {};
  userData: any = {};
  emailPattren = dataConstant.EmailPattren;
  isSubmit = false;
  loginUser : any = [];
  constructor(public _CommonService: CommonService,
    public _LoginService: LoginService,
    private _appCommonService : appService,
    public router: Router) { }

  ngOnInit() {
    // this._CommonService.showLoading();
  }

  onLoggedin(loginform) {
    this.isSubmit = true;
    if (loginform.form.invalid) {
      return;
    } else {
      this._CommonService.showLoading();

      this._LoginService.login(this.authenticationData)
         .subscribe(async (response: any) => {
             this._CommonService.hideLoading();
             if (response && response.data) {
                 const token = response.data.AuthToken; //response;
                 const user = response.data;
                 const permission = response.data.roleName; //response;
                 localStorage.setItem('token', JSON.stringify(token));
                 localStorage.setItem('user', JSON.stringify(user));
                 localStorage.setItem('permission', permission);
                 this.loginUser = this._CommonService.getLoggedInUser();
                 var logsData = {
                  userId: this.loginUser.userId,
                  userName: this.loginUser.userName,
                  moduleName: "Log in",
                  logDescription: "Description : Log In Successfully | " + "User Name : " + this.loginUser.userName + " | Date :" + this._CommonService.formatDate(new Date()) + " | Time : " + this._CommonService.formateTime(new Date()),
                  logDate : this._CommonService.formatDate(new Date()),
                }
                await this._appCommonService.createLogs(logsData).subscribe((res: any) => {      
                  this._CommonService.hideLoading();
                }, error => {
                  this._CommonService.hideLoading();
                  this._CommonService.toastErrorMsg('Error', error);
                });
                 //this.router.navigate(['/dashboard']);
                 window.location.href = '#/dashboard';
             }  else {
                 this.isSubmit = false;
                 this._CommonService.toastErrorMsg(null, response.message, null);
             }

         }, error => {
             this.isSubmit = false;
             this._CommonService.hideLoading();
             if (error != null) {
                 if (error.status == 400) {
                     this._CommonService.toastErrorMsg(null, messageConstant.Common.Invalid, null);
                 } else if (error.status == 401) {
                     this._CommonService.toastErrorMsg(null, messageConstant.Common.UnAuthorized, null);
                 } else {
                     this._CommonService.toastErrorMsg(null, error.message, null);
                 }
             }
         });
    }
  }

}
