import { environment } from '../../environments/environment';

export const urlConstant = {
    accountModule: {
        LoggedIn: environment.APIUrl + 'admin/Login',
        LoggedOut: environment.APIUrl + 'admin/Logout',
        getAllUsers: environment.APIUrl + 'admin/getAllUsers',
        createLogs: environment.APIUrl + 'admin/createLogs',
        getAllLogs: environment.APIUrl + 'admin/getAllLogs',
        Delete: environment.APIUrl + 'admin/Delete',
        getAllHomeByUserId: environment.APIUrl + 'admin/GetAllHomeByUserId',
        getAllCarByUserId: environment.APIUrl + 'admin/GetAllCarByUserId',
        getAllAccidentByUserId: environment.APIUrl + 'admin/GetAllAccidentByUserId',
        getAllOwnerShipByUserId:  environment.APIUrl + 'admin/GetAllOwnerShipByUserId',
        getSummerHomeByUserId: environment.APIUrl + 'admin/GetSummerHomeByUserId',
        getAllRejseByUserId: environment.APIUrl + 'admin/GetAllRejseByUserId',
        getAllCampingByUserId: environment.APIUrl + 'admin/GetAllCampingByUserId',
        getAllMotorCycleByUserId: environment.APIUrl + 'admin/getAllMotorCycleByUserId',
        getAllLifeByUserId: environment.APIUrl + 'admin/getAllLifeByUserId',
        getAllIndboByUserId: environment.APIUrl + 'admin/GetAllIndboByUserId',
        getAllDryByUserId: environment.APIUrl + 'admin/GetAllDryByUserId',
        UpdateCustomer: environment.APIUrl + 'admin/UpdateCustomer',
        GetUserById: environment.APIUrl + 'admin/GetUserById',
        SendInquireyToCompany: environment.APIUrl + 'admin/SendInquireyToCompany',
        GetSentMailHistory: environment.APIUrl + 'admin/GetSentMailHistory',
        getAllBoatByUserId: environment.APIUrl + 'admin/GetAllBoatByUserId',
    },
    MailSettings: {
        GetMailSettings: environment.APIUrl + 'admin/GetMailSettings',
        MailSettingsUpdate: environment.APIUrl + 'admin/MailSettingsUpdate',
    },
    Company:{
        GetList: environment.APIUrl + 'Company/GetList',
        Create: environment.APIUrl + 'Company/Create',
        Delete: environment.APIUrl + 'Company/Delete',
        UpdatePassword: environment.APIUrl + 'Company/UpdatePassword',
    },
    Roles:{
        GetList: environment.APIUrl + 'Roles/GetList',
        Create: environment.APIUrl + 'Roles/Create',
        Delete: environment.APIUrl + 'Roles/Delete',
    },
    RolesPrivileges:{
        GetList: environment.APIUrl + 'RolesPrivileges/GetList',
        Create: environment.APIUrl + 'RolesPrivileges/Create',
    },
    UserManagment:{
        GetList: environment.APIUrl +'AdminSystemUser/GetList',
        Create: environment.APIUrl + 'AdminSystemUser/Create',
        Delete: environment.APIUrl + 'AdminSystemUser/Delete',
    },
    Testimonial:{
        GetList: environment.APIUrl + 'Testimonial/GetList',
        Create: environment.APIUrl + 'Testimonial/Create',
        Delete: environment.APIUrl + 'Testimonial/Delete'
    },
};
