import { Injectable } from '@angular/core';
import { urlConstant } from '../../constant/urlConstant';
import { CommonService} from '../../common/commonService';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })

export class rolesManagement {
    constructor(
    ) { }
      menuPagesList = [
        {
          "PageId": 1,
          "MenuName": "Customer",
          "IsParent": 1,
          "ParentId": 1,
          "CountChild": 3,
          "Level": 0
        },
        {
          "PageId": 2,
          "MenuName": "User Management",
          "IsParent": 1,
          "ParentId": 2,
          "CountChild": 2,
          "Level": 0
        },
        {
            "PageId": 3,
            "MenuName": "SMTP Settings",
            "IsParent": 1,
            "ParentId": 3,
            "CountChild": 0,
            "Level":0
        },
        {
            "PageId": 4,
            "MenuName": "Company Registration",
            "IsParent": 1,
            "ParentId": 4,
            "CountChild": 0,
            "Level":0
        },
        {
            "PageId": 5,
            "MenuName": "Inquiry Sent",
            "IsParent": 1,
            "ParentId": 5,
            "CountChild": 0,
            "Level":0
        },        
        {
            "PageId": 6,
            "MenuName": "New",
            "IsParent": 1,
            "ParentId": 1,
            "CountChild": 0,
            "Level": 1
        },
        {
            "PageId": 7,
            "MenuName": "In Progress",
            "IsParent": 1,
            "ParentId": 1,
            "CountChild": 0,
            "Level": 1
        },
        {
            "PageId": 8,
            "MenuName": "Completed",
            "IsParent": 1,
            "ParentId": 1,
            "CountChild": 0,
            "Level": 1
        },
        {
            "PageId": 9,
            "MenuName": "Users",
            "IsParent": 1,
            "ParentId": 2,
            "CountChild": 0,
            "Level": 1
        },
        {
            "PageId": 10,
            "MenuName": "Roles",
            "IsParent": 1,
            "ParentId": 2,
            "CountChild": 0,
            "Level": 1
        },
        {
          "PageId": 11,
          "MenuName": "Testimonial",
          "IsParent": 1,
          "ParentId": 11,
          "CountChild": 0,
          "Level":0
      },
      ]
       permissionsTypes = [
        {
          "PageId": 1,
          "PrivilegeId": 1,
          "Privileges": "Add",
          "IsSelected": false,
          "ChildCount": 3,
          "ParentId": 1
        },
        {
          "PageId": 1,
          "PrivilegeId": 2,
          "Privileges": "Edit",
          "IsSelected": false,
          "ChildCount": 3,
          "ParentId": 1
        },
        {
          "PageId": 1,
          "PrivilegeId": 3,
          "Privileges": "Delete",
          "IsSelected": false,
          "ChildCount": 3,
          "ParentId": 1
        },
        {
          "PageId": 1,
          "PrivilegeId": 4,
          "Privileges": "View",
          "IsSelected": false,
          "ChildCount": 3,
          "ParentId": 1
        },
        {
          "PageId": 1,
          "PrivilegeId": 5,
          "Privileges": "Export",
          "IsSelected": false,
          "ChildCount": 3,
          "ParentId": 1
        },
        {
          "PageId": 2,
          "PrivilegeId": 1,
          "Privileges": "Add",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 2
        },
        {
          "PageId": 2,
          "PrivilegeId": 2,
          "Privileges": "Edit",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 2
        },
        {
          "PageId": 2,
          "PrivilegeId": 3,
          "Privileges": "Delete",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 2
        },
        {
          "PageId": 2,
          "PrivilegeId": 4,
          "Privileges": "View",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 2
        },
        {
          "PageId": 2,
          "PrivilegeId": 5,
          "Privileges": "Export",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 2
        }, 
        {
          "PageId": 3,
          "PrivilegeId": 1,
          "Privileges": "Add",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 3
        },
        {
          "PageId": 3,
          "PrivilegeId": 2,
          "Privileges": "Edit",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 3
        },
        {
          "PageId": 3,
          "PrivilegeId": 3,
          "Privileges": "Delete",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 3
        },
        {
          "PageId": 3,
          "PrivilegeId": 4,
          "Privileges": "View",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 3
        },
        {
          "PageId": 3,
          "PrivilegeId": 5,
          "Privileges": "Export",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 3
        },
        {
          "PageId": 4,
          "PrivilegeId": 1,
          "Privileges": "Add",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 4
        },
        {
          "PageId": 4,
          "PrivilegeId": 2,
          "Privileges": "Edit",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 4
        },
        {
          "PageId": 4,
          "PrivilegeId": 3,
          "Privileges": "Delete",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 4
        },
        {
          "PageId": 4,
          "PrivilegeId": 4,
          "Privileges": "View",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 4
        },
        {
          "PageId": 4,
          "PrivilegeId": 5,
          "Privileges": "Export",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 4
        },
        {
          "PageId": 5,
          "PrivilegeId": 1,
          "Privileges": "Add",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 5
        },
        {
          "PageId": 5,
          "PrivilegeId": 2,
          "Privileges": "Edit",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 5
        },
        {
          "PageId": 5,
          "PrivilegeId": 3,
          "Privileges": "Delete",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 5
        },
        {
          "PageId": 5,
          "PrivilegeId": 4,
          "Privileges": "View",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 5
        },
        {
          "PageId": 5,
          "PrivilegeId": 5,
          "Privileges": "Export",
          "IsSelected": false,
          "ChildCount": 2,
          "ParentId": 5
        },
        {
          "PageId": 6,
          "PrivilegeId": 1,
          "Privileges": "Add",
          "IsSelected": false,
          "ChildCount": 1,
          "ParentId": 1
        },
        {
          "PageId": 6,
          "PrivilegeId": 2,
          "Privileges": "Edit",
          "IsSelected": false,
          "ChildCount": 1,
          "ParentId": 1
        },
        {
          "PageId": 6,
          "PrivilegeId": 3,
          "Privileges": "Delete",
          "IsSelected": false,
          "ChildCount": 1,
          "ParentId": 1
        },
        {
          "PageId": 6,
          "PrivilegeId": 4,
          "Privileges": "View",
          "IsSelected": false,
          "ChildCount": 1,
          "ParentId": 1
        },
        {
          "PageId": 6,
          "PrivilegeId": 5,
          "Privileges": "Export",
          "IsSelected": false,
          "ChildCount": 1,
          "ParentId": 1
        },
        {
          "PageId": 7,
          "PrivilegeId": 1,
          "Privileges": "Add",
          "IsSelected": false,
          "ChildCount": 0,
          "ParentId": 1
        },
        {
          "PageId": 7,
          "PrivilegeId": 2,
          "Privileges": "Edit",
          "IsSelected": false,
          "ChildCount": 0,
          "ParentId": 1
        },
        {
          "PageId": 7,
          "PrivilegeId": 3,
          "Privileges": "Delete",
          "IsSelected": false,
          "ChildCount": 0,
          "ParentId": 1
        },
        {
          "PageId": 7,
          "PrivilegeId": 4,
          "Privileges": "View",
          "IsSelected": false,
          "ChildCount": 0,
          "ParentId": 1
        },
        {
          "PageId": 7,
          "PrivilegeId": 5,
          "Privileges": "Export",
          "IsSelected": false,
          "ChildCount": 0,
          "ParentId": 1
        },
        {
          "PageId": 8,
          "PrivilegeId": 1,
          "Privileges": "Add",
          "IsSelected": false,
          "ChildCount": 0,
          "ParentId": 1
        },
        {
          "PageId": 8,
          "PrivilegeId": 2,
          "Privileges": "Edit",
          "IsSelected": false,
          "ChildCount": 0,
          "ParentId": 1
        },
        {
          "PageId": 8,
          "PrivilegeId": 3,
          "Privileges": "Delete",
          "IsSelected": false,
          "ChildCount": 0,
          "ParentId": 1
        },
        {
          "PageId": 8,
          "PrivilegeId": 4,
          "Privileges": "View",
          "IsSelected": false,
          "ChildCount": 0,
          "ParentId": 1
        },
        {
          "PageId": 8,
          "PrivilegeId": 5,
          "Privileges": "Export",
          "IsSelected": false,
          "ChildCount": 0,
          "ParentId": 1
        },
        {
          "PageId": 9,
          "PrivilegeId": 1,
          "Privileges": "Add",
          "IsSelected": false,
          "ChildCount": 0,
          "ParentId": 2
        },
        {
          "PageId": 9,
          "PrivilegeId": 2,
          "Privileges": "Edit",
          "IsSelected": false,
          "ChildCount": 0,
          "ParentId": 2
        },
        {
          "PageId": 9,
          "PrivilegeId": 3,
          "Privileges": "Delete",
          "IsSelected": false,
          "ChildCount": 0,
          "ParentId": 2
        },
        {
          "PageId": 9,
          "PrivilegeId": 4,
          "Privileges": "View",
          "IsSelected": false,
          "ChildCount": 0,
          "ParentId": 2
        },
        {
          "PageId": 9,
          "PrivilegeId": 5,
          "Privileges": "Export",
          "IsSelected": true,
          "ChildCount": 0,
          "ParentId": 2
        },
        {
          "PageId": 10,
          "PrivilegeId": 1,
          "Privileges": "Add",
          "IsSelected": false,
          "ChildCount": 1,
          "ParentId": 2
        },
        {
          "PageId": 10,
          "PrivilegeId": 2,
          "Privileges": "Edit",
          "IsSelected": false,
          "ChildCount": 1,
          "ParentId": 2
        },
        {
          "PageId": 10,
          "PrivilegeId": 3,
          "Privileges": "Delete",
          "IsSelected": false,
          "ChildCount": 1,
          "ParentId": 2
        },
        {
          "PageId": 10,
          "PrivilegeId": 4,
          "Privileges": "View",
          "IsSelected": false,
          "ChildCount": 1,
          "ParentId": 2
        },
        {
          "PageId": 10,
          "PrivilegeId": 5,
          "Privileges": "Export",
          "IsSelected": false,
          "ChildCount": 1,
          "ParentId": 2
        },
        {
          "PageId": 11,
          "PrivilegeId": 1,
          "Privileges": "Add",
          "IsSelected": false,
          "ChildCount": 1,
          "ParentId": 11
        },
        {
          "PageId": 11,
          "PrivilegeId": 2,
          "Privileges": "Edit",
          "IsSelected": false,
          "ChildCount": 1,
          "ParentId": 11
        },
        {
          "PageId": 11,
          "PrivilegeId": 3,
          "Privileges": "Delete",
          "IsSelected": false,
          "ChildCount": 1,
          "ParentId": 11
        },
        {
          "PageId": 11,
          "PrivilegeId": 4,
          "Privileges": "View",
          "IsSelected": false,
          "ChildCount": 1,
          "ParentId": 11
        },
        {
          "PageId": 11,
          "PrivilegeId": 5,
          "Privileges": "Export",
          "IsSelected": false,
          "ChildCount": 1,
          "ParentId": 11
        },
      ]
      columnNames = [
        {
          "IsSelected": false,
          "ColumnName": "Add"
        },
        {
          "IsSelected": false,
          "ColumnName": "Delete"
        },
        {
          "IsSelected": false,
          "ColumnName": "Edit"
        },
        {
          "IsSelected": false,
          "ColumnName": "View"
        },
        {
          "IsSelected": false,
          "ColumnName": "Export"
        }
      ]
}


