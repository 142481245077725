import * as internal from "assert";

interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  pageId?: number;
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] =
[
  {
    pageId : 0,
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  {
    pageId:1,
    name: 'Customers',
    // url: '/customers',
    icon: 'fa fa-users',
    children: [
            {
              pageId:6,
              name: 'New',
              url: '/customers/new',
              icon: 'fa fa-list-alt'
            },
            {
              pageId:7,
              name: 'In Progress',
              url: '/customers/inprogress',
              icon: 'fa fa-tasks'
            },
            {
              pageId:8,
              name: 'Completed',
              url: '/customers/completed',
              icon: 'fa fa-th-list'
            }]
  },
  {
    pageId:2,
    name: 'User Management',
    icon: 'fa fa-user',
    children: [            
            {
              pageId:9,
              name: 'Users',
              url: '/usermanagment',
              icon: 'fa fa-th-list'
            },
            {
              pageId:10,
              name: 'Roles',
              url: '/roles',
              icon: 'fa fa-th-list'
            }
          ]
  },
  {
    pageId:3,
    name: 'SMTP Settings',
    url: '/mailsettings',
    icon: 'fa fa-envelope'
  },
  {
    pageId:4,
    name: 'Company Registration',
    url: '/company',
    icon: 'fa fa-building-o'
  },
  {
    pageId:5,
    name: 'Inquiry Sent',
    url: '/inquirysent',
    icon: 'fa fa-file-text'
  },
  {
    pageId:11,
    name: 'Testimonial',
    url: '/testimonial',
    icon: 'fa fa-user'
},
{
  pageId:12,
  name: 'Logs',
  url: '/logs',
  icon: 'fa fa-history'
},
];

export const navCompanyItems: NavData[] =
[
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  {
    name: 'Customers',
    url: '/companycustomers',
    icon: 'fa fa-users'
  }
];
