import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({
  name: 'logsFilter'
})
export class LogsFilterPipe implements PipeTransform {
  transform(array: any[], query: string): any {
    if (query) {
      return _.filter(array, row => (row.userName && row.userName.toLowerCase().indexOf(query.toLowerCase()) > -1)
      || (row.moduleName && row.moduleName.toLowerCase().indexOf(query.toLowerCase()) > -1)
      || (row.logDescription && row.logDescription.toLowerCase().indexOf(query.toLowerCase()) > -1)  
      || (row.logDate && row.logDate.toLowerCase().indexOf(query.toLowerCase()) > -1)      
      );
    }
    return array;
  }

}
