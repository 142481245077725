import { Component, OnDestroy, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navCompanyItems, navItems } from '../../_nav';
import { Router } from '@angular/router';
import { CommonService } from '../../common/commonService';
import { LoginService } from '../../views/login/login.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { appService } from '../../app.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnDestroy {  
  public navItems = [];
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  loginUser = this._commonService.getLoggedInUser();
  loginUserId = this._commonService.getLoggedInUserId();
  loginUserPermission = this._commonService.getLoggedInUserPermission();
  userShortName = null;
  public username = null;

  constructor(public _router: Router,
    public _commonService: CommonService,
    private _appCommonService : appService,
    public _loginService: LoginService,
    @Inject(DOCUMENT) _document?: any) {
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });

    if (!this.loginUser) {
      this._router.navigate(['/login']);
    }

    this.navItems[0] = navItems[0];    
    for(let i = 1; i < navItems.length; i++){ 
      if(navItems[i]['children'] !== undefined){      
          var totalTrue, temp;   
          var childTemp = [];
          var childIndex = 0;       
          for(let j=0; j < navItems[i]['children'].length; j++) {            
            temp  = this.loginUserPermission.filter(xl => xl.PageId == navItems[i]['children'][j]['pageId']);
            totalTrue = temp.filter(el => el.isSelected == true); 
            if(totalTrue.length <= temp.length && totalTrue.length !== 0 ) {                
              childTemp[childIndex] = navItems[i]['children'][j];
              childIndex++;
            }
          }
          if(totalTrue.length !== 0) {
            var nextIndex = this.navItems.length; 
            this.navItems[nextIndex] = navItems[i];
            for(let k =0; k < this.navItems.length; k++) {
                if(this.navItems[k]['pageId'] == totalTrue[0]['ParentId']) {
                  this.navItems[k]['children'] = [];
                  this.navItems[k]['children'] = childTemp;                  
                }
            }
          }
      }
  }
    
    for(let i = 1; i < navItems.length; i++){ 
        var temp = this.loginUserPermission.filter(xl => xl.PageId == navItems[i]['pageId']);
        var totalTrue = temp.filter(el => el.isSelected == true);        
        //console.log(totalTrue)
        if(totalTrue.length <= temp.length && totalTrue.length !== 0 ) {     
          var nextIndex = this.navItems.length;      
          this.navItems[nextIndex] = navItems[i]
        }
        
        if(this.loginUser.userId == 1 || this.loginUser.userName.toLocaleLowerCase() == "Admin".toLocaleLowerCase()) {
          this.navItems[nextIndex] = navItems[i]
        }
    }


    this.navItems = Array.from(new Set(this.navItems)); 
   
    //console.log(this.navItems);
   // this.navItems = navItems;
  //this.navItems = navItems;
    /*if (this.loginUser.IsAdmin) {
       this.navItems = navItems;
    }
    else {
      this.navItems = navCompanyItems;
    }*/

    this.username = this.loginUser.userName;
    if (this.username) {
      const matches = this.username.match(/\b(\w)/g);
      this.userShortName = matches.join('');
    }
  }

  ngOnDestroy(): void {
    this.changes.disconnect();   
  }

  logout() {
    this._commonService.showLoading();
    this.loginUser = this._commonService.getLoggedInUser();
    var logsData = {
     userId: this.loginUser.userId,
     userName: this.loginUser.userName,
     moduleName: "Log out",
     logDescription: "Description : Logout Successfully | " + "User Name : " + this.loginUser.userName + " | Date :" + this._commonService.formatDate(new Date()) + " | Time : " + this._commonService.formateTime(new Date()),
     logDate : this._commonService.formatDate(new Date()),
   }
    this._appCommonService.createLogs(logsData).subscribe((res: any) => {      
     this._commonService.hideLoading();
   }, error => {
     this._commonService.hideLoading();
     this._commonService.toastErrorMsg('Error', error);
   });
       localStorage.removeItem('token');
       localStorage.removeItem('user');
       localStorage.removeItem("permission");
        window.localStorage.clear();
        this._router.navigate(['/login']);
    /*this._loginService.logout(this.loginUser).subscribe( (response: any)=>{      
      this._commonService.hideLoading();
      if (response.messageType === "success") {
       
        this.loginUser = this._commonService.getLoggedInUser();
        var logsData = {
         userId: this.loginUser.userId,
         userName: this.loginUser.userName,
         moduleName: "Log out",
         logDescription: "Description : Logout Successfully | " + "User Name : " + this.loginUser.userName + " | Date :" + this._commonService.today.toLocaleDateString() + " Time : " + this._commonService.today.toLocaleTimeString(),
         logDate : this._commonService.today.toDateString(),
       }
        this._appCommonService.createLogs(logsData).subscribe((res: any) => {      
         this._commonService.hideLoading();
       }, error => {
         this._commonService.hideLoading();
         this._commonService.toastErrorMsg('Error', error);
       });
       localStorage.removeItem('token');
       localStorage.removeItem('user');
       localStorage.removeItem("permission");
        window.localStorage.clear();
        this._router.navigate(['/login']);
      } else {

      }
    })*/
    
  }
}


