import { Injectable } from '@angular/core';
import { urlConstant } from '../../constant/urlConstant';
import { CommonService } from '../../common/commonService';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(public _commonService: CommonService, public http: HttpClient) { }

  login(data) {
    return this.http.post(urlConstant.accountModule.LoggedIn, data)
  }
  logout(data) {
    return this.http.post(urlConstant.accountModule.LoggedOut, data)
  }
  
}
