import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { LoginComponent } from './views/login/login.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import {AuthGuard} from './common/auth.guard'
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { MailSettingsComponent } from './views/mailsettings/mailsettings.component';
import { CompanyComponent } from './views/company/company.component';
import { InquiryComponent } from './views/inquiry/inquiry.component';
import { TestimonialComponent } from './views/testimonial/testimonial.component';
import { LogsComponent } from './views/logs/logs.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    },
  },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot Password Page'
    },
  },
  {
    path: 'resetPassword/:token/:userId',
    component: ResetPasswordComponent,
    data: {
      title: 'Reset Password Page'
    },
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    // data: {
    //   title: 'Home'
    // },
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          title: 'Dashboard'
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'customers/new',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
        data: {
          title: 'New Customers'
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'customers/inprogress',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
        data: {
          title: 'In Progress Customers'
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'customers/completed',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
        data: {
          title: 'Completed Customers'
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'roles',
        loadChildren: () => import('./views/roles/roles.module').then(m => m.RolesModule),
        data: {
          title: 'Roles'
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'usermanagment',
        loadChildren: () => import('./views/user-managments/user-managments.module').then(m => m.UserManagmentsModule),
        data: {
          title: 'User Management'
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'mailsettings',
        component: MailSettingsComponent,
        data: {
          title: 'SMTP Settings'
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'company',
        component: CompanyComponent,
        data: {
          title: 'Company Registration'
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'inquirysent',
        component: InquiryComponent,
        data: {
          title: 'Inquiry Sent'
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'testimonial',
        component: TestimonialComponent,
        data: {
          title: 'Testimonial'
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'logs',
        component: LogsComponent,
        data: {
          title: 'Logs'
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'customerDetails/:userId',
        loadChildren: () => import('./views/userdetail/userdetail.module').then(m => m.UserDetailModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'companyCustomerDetails/:userId',
        loadChildren: () => import('./views/company-customer-details/company-customer-details.module').then(m => m.CompanyCustomerDetailModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'car/:userId',
        loadChildren: () => import('./views/car/car.module').then(m => m.CarModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'house/:userId',
        loadChildren: () => import('./views/house/house.module').then(m => m.HouseModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'holding-house/:userId',
        loadChildren: () => import('./views/holding-house/holding-house.module').then(m => m.HoldingHouseModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'accident/:userId',
        loadChildren: () => import('./views/accident/accident.module').then(m => m.AccidentModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'ownership/:userId',
        loadChildren: () => import('./views/ownership/ownership.module').then(m => m.OwnerShipModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'rejse/:userId',
        loadChildren: () => import('./views/rejse/rejse.module').then(m => m.RejseModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'indbo/:userId',
        loadChildren: () => import('./views/indbo/indbo.module').then(m => m.IndboModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'boat/:userId',
        loadChildren: () => import('./views/boat/boat.module').then(m => m.BoatModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'dry/:userId',
        loadChildren: () => import('./views/dry/dry.module').then(m => m.DryModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'motorcycle/:userId',
        loadChildren: () => import('./views/motorcycle/motorcycle.module').then(m => m.MotorCycleModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'life/:userId',
        loadChildren: () => import('./views/life/life.module').then(m => m.LifeModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'camping/:userId',
        loadChildren: () => import('./views/camping/camping.module').then(m => m.CampingModule),
        canActivate: [AuthGuard],
      },
    ]
  },

];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
