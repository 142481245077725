import { Component, OnInit } from '@angular/core';
import { CommonService} from '../../common/commonService';
import { appService } from '../../app.service';
import { dataConstant } from '../../constant/dataConstant';
import { _ } from 'underscore'; 
import * as moment from 'moment';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
allLogsList: any = [];
allUsersList: any = [];
allModuleList: any = [];
tempUserList: any = [];
searchModule = undefined;
searchUserName = undefined;
searchStartDate =undefined;
searchEndDate =undefined;
filterQuery = '';
  constructor(private _commonServices: CommonService,
              private _appServices : appService) { }

  ngOnInit() {
    this._commonServices.showLoading();
    this._appServices.getLogs().subscribe((res: any) => {
      if(res.messageType == "success") {
        this.allLogsList = res.data;
        this.allLogsList.sort((a, b) => {
          return <any>parseInt(b.logId) - <any>parseInt(a.logId);
        })
        this.allUsersList = Array.from(new Set(res.data.map( data => { return data.userName})));
        this.allModuleList = Array.from(new Set(res.data.map( data => { return data.moduleName})));
        this.tempUserList = _.clone(this.allLogsList);
        this._commonServices.hideLoading();
      }
    });
  }
 
  changeFilter(){    
    if ( this.searchModule === undefined && this.searchUserName === undefined && this.searchEndDate === undefined && this.searchStartDate === undefined) {
      this.allLogsList =  this.tempUserList;
    } else if(this.searchModule !== undefined) {
      this.allLogsList = this.allLogsList.filter(x => x.moduleName.toUpperCase() === this.searchModule.toUpperCase());      
    } else if ( this.searchUserName !== undefined) {
      this.allLogsList = this.allLogsList.filter(x => x.userName === this.searchUserName);
    }else if(this.searchStartDate != undefined && this.searchEndDate != undefined ){
      this.allLogsList =  this.tempUserList;
      var temp = [];
      for(var i=0; i < this.allLogsList.length; i++) {
        this.allLogsList[i].logDate = moment(new Date(this.allLogsList[i].logDate)).format('YYYY-MM-DD');
        if(new Date(this.allLogsList[i].logDate) >= new Date(this.searchStartDate) && new Date(this.allLogsList[i].logDate) <= new Date(this.searchEndDate)) {        
          temp[i] = this.allLogsList[i];
        }
      }
      this.allLogsList = [];
      this.allLogsList = temp.filter((a) => a);
    } else {
      this.allLogsList =  this.tempUserList;
    }
    if(this.allLogsList.length == 0){
      this.allLogsList =  this.tempUserList;
    }    
  }

}
